export default {
    rules: {
        'pfizer.com': '/pfizer-saml/login',
        'colpal.com': '/colgate-saml/login',
        'hillspet.com': '/colgate-saml/login',
        'hello-products.com': '/colgate-saml/login',
        'filorga.com': '/colgate-saml/login',
        'pcaskin.com': '/colgate-saml/login',
        'travelers.com': '/travelers-saml/login',
        'rtx.com': '/collins-saml/login',
        'utc.com': '/collins-saml/login',
        'pwc.cas': '/collins-saml/login',
        'ray.com': '/collins-saml/login',
        'raytheon.com': '/collins-saml/login',
        'adxuser.com': '/collins-saml/login',
        'collins.com': '/collins-saml/login',
        'prattwhitney.com': '/collins-saml/login',
        'pwc.ca': '/collins-saml/login',
        'raytheon.co.uk': '/collins-saml/login'
    },

    exceptions: [
        'emtech@pfizer.com'
    ]
};
